<template>
  <v-card class="pa-4" :class="darkmode || 'grey lighten-5'" flat>
    <div class="d-flex flex-row">
      <div class="mb-5 font-weight-bold" style="cursor: pointer; width: 100px">
        <v-btn @click="$router.go(-1)" depressed>
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t("app.back") }}
        </v-btn>
      </div>
      <div class="ml-5 d-flex title">
        <span class="pt-1 pl-2"
          >{{
            $t("e_test.question_for_etest") +
              " " +
              (info.lesson_name ? info.lesson_name : "")
          }}
        </span>
      </div>
    </div>
    <div
      v-if="$vuetify.breakpoint.mdAndUp"
      :class="darkmode ? 'dark-mode' : 'white'"
      class="d-flex pa-5"
      no-gutters
    >
      <v-text-field
        :value="info.lesson_name"
        :loading="lessonLoading"
        disabled
        outlined
        dense
        hide-details
        style="max-width: 220px"
        class="d-inline-block mr-2"
      />
      <v-select
        v-model="bodyRequest.question.filter.grade"
        :items="listGrade"
        :label="$t('schedule.choose_grade')"
        :loading="loadingGrade"
        :disabled="loadingGrade"
        item-value="id"
        item-text="grade"
        multiple
        outlined
        hide-details
        dense
        class="mr-2 d-inline-block select-225"
      >
        <template slot="prepend-item">
          <v-list-item @click="handleClickAll()">
            <v-list-item-action>
              <v-checkbox v-model="selectAllGrade" @click="handleClickAll()" />
            </v-list-item-action>
            <v-list-item-content :class="selectAllGrade && 'primary--text'">
              {{ $t("app.select_all") }}
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:selection="{ item, index }">
          <span v-if="index === 0" class="mr-2">{{ item.grade }}</span>
          <span v-if="index === 1" class="grey--text">
            (+{{ bodyRequest.question.filter.grade.length - 1 }} others)
          </span>
        </template>
      </v-select>
      <v-select
        v-model="modelPeriode"
        :items="dataSchoolYear"
        :label="$t('app.select') + ' Periode'"
        :loading="loadingSchoolYer"
        :disabled="loadingSchoolYer"
        outlined
        hide-details
        dense
        class="mr-2 d-inline-block select-225"
        @change="changePeriode"
      />
      <v-select
        :items="questionType"
        :label="$t('app.select') + ' ' + $t('e_test.question_type')"
        :disabled="disabledQuestionType"
        item-text="name"
        item-value="id"
        outlined
        hide-details
        dense
        class="mr-2 d-inline-block select-225"
        @change="getListBank"
      />
      <v-spacer />
      <v-btn
        :disabled="!selectedQuestion.length != 0"
        :dark="selectedQuestion.length != 0"
        :loading="loadingSave"
        color="gradient-primary"
        depressed
        @click="save"
        >{{ $t("app.save") }} <v-icon class="ml-2">mdi-content-save</v-icon>
      </v-btn>
    </div>

    <div v-else class="d-flex align-center">
      <v-btn color="primary" @click="sheet = !sheet" depressed
        ><v-icon>mdi-filter-outline</v-icon></v-btn
      >
      <v-spacer />
      <v-btn
        :disabled="!selectedQuestion.length != 0"
        :dark="selectedQuestion.length != 0"
        :loading="loadingSave"
        color="gradient-primary"
        depressed
        @click="save"
        >{{ $t("app.save") }} <v-icon class="ml-2">mdi-content-save</v-icon>
      </v-btn>
    </div>

    <v-bottom-sheet v-model="sheet">
      <v-sheet class="text-center pa-6">
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              :value="info.lesson_name"
              :loading="lessonLoading"
              disabled
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              v-model="bodyRequest.question.filter.grade"
              :items="listGrade"
              :label="$t('schedule.choose_grade')"
              :loading="loadingGrade"
              :disabled="loadingGrade"
              item-value="id"
              item-text="grade"
              multiple
              outlined
              dense
            >
              <template slot="prepend-item">
                <v-list-item @click="handleClickAll()">
                  <v-list-item-action>
                    <v-checkbox
                      v-model="selectAllGrade"
                      @click="handleClickAll()"
                    />
                  </v-list-item-action>
                  <v-list-item-content
                    :class="selectAllGrade && 'primary--text'"
                  >
                    {{ $t("app.select_all") }}
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="mr-2">{{ item.grade }}</span>
                <span v-if="index === 1" class="grey--text">
                  (+{{ bodyRequest.question.filter.grade.length - 1 }} others)
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              v-model="modelPeriode"
              :items="dataSchoolYear"
              :label="$t('app.select') + ' Periode'"
              :loading="loadingSchoolYer"
              :disabled="loadingSchoolYer"
              outlined
              dense
              @change="changePeriode"
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              :items="questionType"
              :label="$t('app.select') + ' ' + $t('e_test.question_type')"
              :disabled="disabledQuestionType"
              item-text="name"
              item-value="id"
              outlined
              dense
              @change="getListBank"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>

    <Pagination
      :to="dataTable.body.to"
      :total="dataTable.body.total"
      :currentPage="table.page"
      :nextPage="dataTable.body.next_page_url"
      :prevPage="dataTable.body.prev_page_url"
      :handler="paginateLimit"
      @next="arrowPage"
      @back="arrowPage"
      @search="handleSearch"
    />
    <v-data-table
      v-model="selectedQuestion"
      :headers="tableHeaders"
      :items="dataTable.body.data"
      :items-per-page="table.limit"
      :page.sync="table.page"
      :loading="table.loading"
      show-select
      item-key="id"
      show-expand
      hide-default-footer
      disable-pagination
      class="elevation-0"
    >
      <template #item.type="{ item }">
        {{ item.type === "pg" ? $t("e_test.multiple_choice") : "Essay" }}
      </template>
      <template #item.point="{ item }">
        {{ Number(item.point) }}
      </template>
      <template #item.kd="{ item }">
        <span v-if="item.kd">
          {{ getKD(item.kd) }}
        </span>
        <v-edit-dialog
          v-else
          :cancel-text="$t('app.cancel')"
          :save-text="$t('app.save')"
          :return-value.sync="modelKD"
          large
          transition="fade-transition"
          @save="saveKD(item.id)"
        >
          <v-btn :disabled="table.loading" color="error" text>
            {{ $t("e_test.msg.kd_empty") }}
          </v-btn>
          <template #input>
            <v-autocomplete
              v-model="modelKD"
              :items="dataKD"
              :item-text="item => getKD(item.id)"
              item-value="id"
              single-line
              class="caption"
            />
          </template>
        </v-edit-dialog>
      </template>
      <template #item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          v-if="item.kd"
          :value="isSelected"
          @input="select($event)"
        />
        <v-tooltip v-else right>
          <template #activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="grey">
              mdi-checkbox-blank-outline
            </v-icon>
          </template>
          <span>{{ $t("e_test.msg.kd_empty") }}</span>
        </v-tooltip>
      </template>
      <template #item.question="{ item }">
        <span v-html="truncateHtml(item.question)" />
      </template>
      <template #item.number="{ item }">
        {{ dataTable.body.data.indexOf(item) + 1 }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-5 pl-16">
          <div
            class="question"
            v-html="item.question"
            @click="viewImage($event)"
          />
          <v-row>
            <v-col
              v-for="data in item.pg"
              :key="data.id"
              cols="12"
              sm="6"
              class="my-2"
            >
              <div
                :class="!data.key || 'font-weight-bold'"
                class="d-flex flex-row"
              >
                <span class="mr-2">{{ data.code }}.</span>
                <span
                  v-html="data.answer"
                  class="answer"
                  @click="viewImage($event)"
                />
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-3" />
          <span class="font-weight-bold">{{ $t("e_test.explain") }}</span>
          <div v-if="item.type === 'pg'">{{ item.discussion || "-" }}</div>
          <div v-else>
            <span v-if="item.esay.key" v-html="item.esay.key" />
            <span v-else>-</span>
          </div>
        </td>
      </template>
    </v-data-table>
    <ViewImage
      :viewFile="viewFile"
      :urlFile="urlFile"
      @close="viewFile = false"
    />
  </v-card>
</template>

<script>
import store from "@/store";
import i18n from "@/i18n";
import {
  getQuestionBankLocal,
  eTestLessonList,
  addFromBank,
  setQuestionBankKD
} from "@/api/admin/academic/eTest";
import { get_grade_list } from "@/api/admin/schoolClass";
import { get_school_year_list } from "@/api/admin/schoolClass";
import truncate from "truncate-html";
import { listCompetenciesByLessonDetail } from "@/api/admin/academic/basicCompetencies";

let typingTimer;
const doneTypingInterval = 750;

export default {
  components: {
    Pagination: () => import("../../../components/Pagination"),
    ViewImage: () => import("../../../components/ViewImage")
  },
  created() {
    this.getGrade();
    this.getSchoolYear();
    this.eTestLesson();
    this.getCompetencies(this.$route.params.lesson);
  },
  computed: {
    darkmode() {
      return store.getters.g_darkmode;
    }
  },
  watch: {
    "bodyRequest.question.filter": {
      handler(d) {
        if (d.grade.length === this.listGrade.length) {
          this.selectAllGrade = true;
        } else {
          this.selectAllGrade = false;
        }
        if (d.grade.length && d.periode.length) {
          this.disabledQuestionType = false;
        } else {
          this.disabledQuestionType = true;
        }
        if (
          d.grade.length &&
          d.periode.length &&
          d.type.length &&
          !this.table.loading
        ) {
          this.getBankQuestion();
        }
      },
      deep: true
    }
  },
  data() {
    return {
      modelKD: null,
      dataKD: [],
      modelPeriode: 0,
      menuSubject: false,
      sheet: false,
      disabledQuestionType: true,
      tableHeaders: [
        { text: "#", value: "number", width: 70 },
        {
          text: i18n.t("e_test.question"),
          value: "question"
        },
        {
          text: i18n.t("e_test.basic_competencies"),
          value: "kd"
        },
        {
          text: i18n.t("e_test.created_by"),
          value: "created_name"
        },
        {
          text: i18n.t("e_test.weight"),
          value: "point"
        },
        {
          text: i18n.t("e_test.question_type"),
          value: "type",
          width: 130
        },
        {
          text: i18n.t("e_test.weight"),
          value: "point"
        },
        { text: i18n.t("app.action"), value: "data-table-expand" }
      ],
      table: {
        limit: 10,
        page: 1,
        search: "",
        loading: false
      },
      dataTable: {
        header: {},
        body: {
          data: []
        }
      },
      listGrade: [],
      loadingGrade: false,
      dataSchoolYear: [],
      loadingSchoolYer: false,
      questionType: [
        { id: "pg", name: i18n.t("e_test.multiple_choice") },
        { id: "esay", name: "Esay" }
      ],
      selectAllGrade: false,
      info: {},
      lessonLoading: false,
      selectedQuestion: [],
      loadingSave: false,
      bodyRequest: {
        question: {
          filter: {
            lesson: [],
            grade: [],
            periode: [],
            type: []
          }
        }
      },
      viewFile: false,
      urlFile: "",
      loadingKD: false
    };
  },
  methods: {
    async saveKD(idQuestionBank) {
      this.table.loading = true;
      const body = { kd: this.modelKD, id: idQuestionBank };
      setQuestionBankKD(body)
        .then(res => {
          if (res.data.code)
            this.snackBar(true, this.$i18n.t("e_test.msg.success_update_kd"));
          else this.snackBar(false, res.data.message);
          this.modelKD = 0;
          this.eTestLesson();
        })
        .catch(() => {
          this.table.loading = false;
          this.modelKD = 0;
        });
    },
    getKD(idKD) {
      const kd = this.dataKD.find(kd => kd.id === idKD);
      if (kd) return `${kd.core_competencies} - ${kd.kd_chapter}`;
    },
    async getCompetencies(lesson) {
      if (lesson) {
        this.loadingKD = true;
        try {
          const response = await listCompetenciesByLessonDetail({
            lesson: lesson
          });
          if (response.data.code === 1) {
            this.dataKD = response.data.data;
          } else {
            this.snackBar(false, response.data.message);
          }
        } catch (error) {
          console.error("getCompetencies()\n", error);
          this.snackBar(false, error);
        }
        this.loadingKD = false;
      }
    },
    viewImage(event) {
      if (event.target.src) {
        this.viewFile = true;
        this.urlFile = event.target.src;
      }
    },
    truncateHtml: html =>
      truncate(html, 10, { byWords: true, stripTags: true }),
    async save() {
      this.loadingSave = true;
      const body = {
        question_bank: this.selectedQuestion.map(d => d.id),
        etest_class: this.$route.params.etestClass
      };
      try {
        const response = await addFromBank(body);
        if (response.data.code) {
          this.selectedQuestion = [];
          this.getBankQuestion();
          this.snackBar(
            true,
            this.$i18n.t("e_test.msg.success_add_question_bank")
          );
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.loadingSave = false;
    },
    async getListBank(id) {
      this.bodyRequest.question.filter.type = [id];
      this.getBankQuestion();
    },
    changePeriode(periode) {
      this.bodyRequest.question.filter.periode = [periode];
    },
    handleClickAll() {
      this.selectAllGrade = !this.selectAllGrade;
      this.bodyRequest.question.filter.grade = [];
      if (this.selectAllGrade) {
        this.bodyRequest.question.filter.grade = this.listGrade.map(d => d.id);
      }
    },
    async getSchoolYear() {
      this.loadingSchoolYer = true;
      try {
        const response = await get_school_year_list();
        if (response.data.code === 1) {
          response.data.data.map(d => {
            this.dataSchoolYear.push(d.start_year);
            this.dataSchoolYear.push(d.end_year);
          });
          [...new Set(this.dataSchoolYear.reverse())];
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.loadingSchoolYer = false;
    },
    getGrade() {
      this.loadingGrade = true;
      get_grade_list()
        .then(res => {
          if (res.data.code == 1) {
            let r = res.data.data;
            this.listGrade = r;
          } else {
            this.snackBar(true, res.data.message);
          }
          this.loadingGrade = false;
        })
        .catch(err => {
          this.loadingGrade = false;
          this.snackBar(true, err);
        });
    },
    paginateLimit(page, limit) {
      this.table.page = page;
      this.table.limit = limit;
      this.getBankQuestion();
    },
    handleSearch(value) {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.table.search = value;
        this.getBankQuestion();
      }, doneTypingInterval);
    },
    arrowPage(page) {
      this.table.page = page;
      this.getBankQuestion();
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    async eTestLesson() {
      // reset grade
      this.bodyRequest.question.filter.grade = [];
      this.bodyRequest.question.filter.periode = [];
      const currentDate = new Date();
      this.lessonLoading = true;
      try {
        const response = await eTestLessonList({
          id: this.$route.params.etestClass
        });
        if (response.data.code === 1) {
          this.info = response.data.data.header;
          // auto select current grade
          this.bodyRequest.question.filter.grade.push(this.info.grade);
          this.modelPeriode = currentDate.getFullYear();
          this.bodyRequest.question.filter.periode.push(
            currentDate.getFullYear()
          );
          this.bodyRequest.question.filter.lesson = [this.info.lesson];
          this.getBankQuestion();
        } else {
          this.snackBar(false, response.data.data);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.lessonLoading = false;
    },
    async getBankQuestion() {
      this.table.loading = true;
      const body = {
        etest_class: this.info.etest_class,
        filter: { ...this.bodyRequest.question.filter },
        ...this.table
      };
      try {
        const response = await getQuestionBankLocal(body);
        if (response.data.code === 1) {
          this.dataTable = response.data.data;
        } else {
          this.snackBar(false, response.data.data);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.table.loading = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.dark-mode {
  background: #121212;
  border: 10px solid #424242;
}
</style>
